import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "TInterbankLiabilityAccountStatement",
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
        modal: {
          showModalSearchInterbankLiability: false,
        },
        listElement: {
          interbankLiabilityStatement: {
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          dateTo: "",
          sorting: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      form: {
        dateFrom: "",
        dateTo: "",
        interbankLiability: {
          header: {
            accountNumber: 0,
            accountName: "",
            biCode: "",
            bankName: "",
            accountBranch: "",
            officeCode: "",
            officeName: "",
            officeAddress: "",
            balance: 0,
            nominalBlock: 0,
            balanceUsable: 0,
            interbankLiabilityTypeName: "",
            startDate: "",
            endDate: "",
          },
        },
      },
      options: {
        urutkanBerdasarkan: [
          { value: "ASC", text: "Terlama - Terbaru" },
          { value: "DESC", text: "Terbaru - Terlama" },
        ],
      },
      table: {
        header: {
          transaction: [
            {
              key: "transactionDate",
              label: "Tanggal",
            },
            {
              key: "documentProofNumber",
              label: "No Bukti Dokumen",
            },
            {
              key: "transactionCode",
              label: "No Transaksi",
            },
            {
              key: "description",
              label: "Keterangan",
            },
            {
              key: "nominalDebit",
              label: "Mutasi Debet",
            },
            {
              key: "nominalCredit",
              label: "Mutasi Kredit",
            },
            {
              key: "balanceEnd",
              label: "Saldo Akhir",
            },
          ],
        },
        data: {
          transaction: [],
        },
      },
    };
  },
  methods: {
    async searchInterbankLiabilityAccountStatement() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "interbankliabilityDetail/account-statement",
            payload: {
              accountNumber: this.property.filterDto.accountNumber,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              sorting: this.property.filterDto.sorting,
            },
          });
          await this.simpleWait(2000);
          if (resp.data.code === "SUCCESS") {
            this.table.data.transaction = [];
            this.property.modal.showModalSearchInterbankLiability = false;
            this.form.dateFrom = this.property.filterDto.dateFrom;
            this.form.dateTo = this.property.filterDto.dateTo;
            this.property.filterDto.accountNumber = "";
            this.property.filterDto.dateFrom = "";
            this.property.filterDto.dateTo = "";
            await this.simpleWait(500);
            this.form.interbankLiability.header.officeName =
              resp.data.data.header.officeName;
            this.form.interbankLiability.header.accountName =
              resp.data.data.header.accountName;
            this.form.interbankLiability.header.officeAddress =
              resp.data.data.header.officeAddress;
            this.form.interbankLiability.header.accountNumber =
              resp.data.data.header.accountNumber;
            this.form.interbankLiability.header.balance =
              resp.data.data.header.balance;
            this.form.interbankLiability.header.nominalBlock =
              resp.data.data.header.nominalBlock;
            this.form.interbankLiability.header.balanceUsable =
              resp.data.data.header.balanceUsable;
            this.form.interbankLiability.header.interbankLiabilityTypeName = resp
              .data.data.header.rinterbankLiabilityType
              ? resp.data.data.header.rinterbankLiabilityType
                  .interbankLiabilityTypeName
              : "";
            if (resp.data.data.detail) {
              this.table.data.transaction = resp.data.data.detail;
              this.preparePeriode();
            } else {
              this.table.data.transaction = [];
              this.property.listElement.interbankLiabilityStatement.message =
                "Belum Ada Transaksi";
            }
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1000);
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.property.animation.submitLoading = false;
        }
      }
    },
    closeModalCheckingInterbankLiability() {
      this.property.modal.showModalSearchInterbankLiability = false;
      this.propertyfilterDto.accountNumber = "";
      this.propertyfilterDto.dateFrom = "";
      this.propertyfilterDto.dateTo = "";
    },
    printInterbankLiabilityAccountStatement() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.transaction.map((index) => {
        headerFinal.push(index.label);
      });
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.transaction.map((index) => {
        finalDataTable.push([
          `${new Date(index.transactionDate).getDate()}-${new Date(
            index.transactionDate
          ).getMonth()}-${new Date(index.transactionDate).getFullYear()}`,
          index.documentProofNumber,
          index.transactionCode,
          index.description,
          `${
            index.nominalDebit
              ? index.nominalDebit
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.nominalCredit
              ? index.nominalCredit
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.balanceEnd
              ? index.balanceEnd.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
        ]);
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.setFontSize(10).text(`Rekening Koran ABP`, pageWidth / 2 - 1, 0.6);
      doc.setFontSize(8).text(`Alamat Kantor Cabang`, 0.55, 1.3);
      doc
        .setFontSize(8)
        .text(` ${this.form.interbankLiability.header.officeName}`, 0.55, 1.5);
      doc.setFontSize(8).text(`Kepada Yth`, 0.55, 1.8);
      doc
        .setFontSize(8)
        .text(`${this.form.interbankLiability.header.accountName}`, 0.55, 2.0);
      doc
        .setFontSize(8)
        .text(
          `${this.form.interbankLiability.header.officeAddress}`,
          0.55,
          2.2
        );
      doc
        .setFontSize(8)
        .text(
          `Tipe Rekening  :  ${this.form.interbankLiability.header.interbankLiabilityTypeName}`,
          pageWidth / 2 + 1,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Nomor Rekening  :   ${this.form.interbankLiability.header.accountNumber}`,
          pageWidth / 2 + 1,
          1.5
        );
      doc
        .setFontSize(8)
        .text(
          `Saldo Akhir  :  ${
            this.form.interbankLiability.header.balance
              ? this.form.interbankLiability.header.balance
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          } `,
          pageWidth / 2 + 1,
          1.7
        );
      doc
        .setFontSize(8)
        .text(
          `Jumlah Blokir  :  ${
            this.form.interbankLiability.header.nominalBlock
              ? this.form.interbankLiability.header.nominalBlock
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          } `,
          pageWidth / 2 + 1,
          1.9
        );
      doc
        .setFontSize(8)
        .text(
          `Saldo Yang Bisa Digunakan  :  ${
            this.form.interbankLiability.header.balanceUsable
              ? this.form.interbankLiability.header.balanceUsable
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          } `,
          pageWidth / 2 + 1,
          2.1
        );
      doc
        .setFontSize(8)
        .text(
          `Periode  ${this.form.interbankLiability.header.startDate} S/D ${this.form.interbankLiability.header.endDate}`,
          pageWidth / 2 + 1,
          2.3
        );

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 7,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 3.0 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    preparePeriode() {
      if (this.table.data.transaction !== []) {
        // START DATE
        this.form.interbankLiability.header.startDate = `${new Date(
          this.table.data.transaction[0].transactionDate
        ).getDate()} ${
          this.property.accessories.day[
            new Date(this.table.data.transaction[0].transactionDate).getMonth()
          ]
        } ${new Date(
          this.table.data.transaction[0].transactionDate
        ).getFullYear()}`;

        // END DATE
        this.form.interbankLiability.header.endDate = `${new Date(
          this.table.data.transaction[
            this.table.data.transaction.length - 1
          ].transactionDate
        ).getDate()} ${
          this.property.accessories.day[
            new Date(
              this.table.data.transaction[
                this.table.data.transaction.length - 1
              ].transactionDate
            ).getMonth()
          ]
        } ${new Date(
          this.table.data.transaction[
            this.table.data.transaction.length - 1
          ].transactionDate
        ).getFullYear()}`;
      }
    },
    async sortingInterbankLiabilityAccountStatement(event) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.table.data.transaction = [];
        this.property.listElement.interbankLiabilityStatement.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "interbankliabilityDetail/account-statement",
            payload: {
              accountNumber: this.form.interbankLiability.header.accountNumber,
              dateFrom: this.form.dateFrom,
              dateTo: this.form.dateTo,
              sorting: event,
            },
          });
          if (resp.data.code === "SUCCESS") {
            if (resp.data.data.detail) {
              this.table.data.transaction = resp.data.data.detail;
              this.preparePeriode();
            } else {
              this.table.data.transaction = [];
              this.property.listElement.interbankLiabilityStatement.message =
                "Belum Ada Transaksi";
            }
          } else {
            this.property.listElement.interbankLiabilityStatement.message =
              resp.data.message;
          }
        } catch (error) {
          this.handleCatchErrorInterbankLiabilityAccountStatement(error);
        } finally {
          await this.simpleWait(500);
          this.property.listElement.interbankLiabilityStatement.downloading = false;
        }
      }
    },
    handleCatchErrorInterbankLiabilityAccountStatement(error) {
      console.log(error.response);
      this.table.data.transaction = [];
      this.property.listElement.interbankLiabilityStatement.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    defaultMessageTable() {
      this.property.listElement.interbankLiabilityStatement.message =
        "Pilih Nomor Rekening Terlebih Dahulu";
    },
    prepareInterbankLiabilityStatement() {
      this.defaultMessageTable();
      this.property.filterDto.sorting = "DESC";
    },
  },
  mounted() {
    this.prepareInterbankLiabilityStatement();
  },
};
